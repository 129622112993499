var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.loadingTable)?_c('v-progress-linear',{attrs:{"color":"indigo","height":"10","indeterminate":""}}):_vm._e(),(!_vm.loadingTable)?_c('v-card',{staticClass:"pa-11 mt-9",style:({
      height: '74vh',
    }),attrs:{"elevation":"9","rounded":"xl"}},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"overflow-y-auto msg-window",attrs:{"cols":"12","sm":"3"}},[_c('v-list',{attrs:{"two-line":"","color":"rgba(0,0,0,0)"}},[_c('v-list-item-group',{attrs:{"active-class":"blue lighten-4"},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}},[_vm._l((_vm.conversations),function(conversation,index){return [_c('v-list-item',{key:index},[_c('v-list-item-avatar',[_c('v-img',{attrs:{"src":'https://cdn.vuetifyjs.com/images/lists/1.jpg'}})],1),[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(conversation.name))]),_c('v-list-item-subtitle',{domProps:{"textContent":_vm._s(_vm.lastMessage(conversation.id))}})],1)]],2),_c('v-divider',{key:conversation.id})]})],2)],1)],1),_c('v-col',{staticClass:"border msg-window",attrs:{"cols":"12","sm":"9"}},[(_vm.selected != undefined)?_c('div',{style:('display:flex; flex-direction: column'),attrs:{"flex":""}},_vm._l((_vm.filteredMsgs(_vm.conversations[_vm.selected].id)),function(msg){return _c('div',{key:msg.id,style:(msg.from != _vm.UID ? 'align-self: end' : 'align-self: start'),attrs:{"color":'rgba(0, 0, 0, 0)',"flat":""},on:{"mouseover":function($event){return _vm.readMessage(msg)}}},[(msg.from != _vm.UID)?_c('v-spacer'):_vm._e(),_c('v-card',{staticClass:"my-3 mr-2",style:({
                  background:
                    msg.from === _vm.UID ? 'white' : 'rgb(168, 199, 225)',
                }),attrs:{"rounded":"xl","width":"350px"}},[_c('v-list-item',{attrs:{"two-line":""}},[_c('v-list-item-content',[_c('p',{staticClass:"my-3"},[_vm._v(_vm._s(msg.msg_content))]),_c('v-list-item-subtitle',[(msg.unread === false)?_c('v-icon',{attrs:{"dense":""}},[_vm._v("mdi-check-all")]):_vm._e(),_vm._v(" "+_vm._s(_vm._f("date")(msg.timestamp,"date"))+" ")],1)],1),_c('v-list-item-icon',[_c('v-menu',{attrs:{"offset-y":"","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dense":""}},[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',[(msg.from === _vm.UID)?_c('v-list-item',{on:{"click":function($event){return _vm.removeMessage(msg.id)}}},[_c('v-list-item-title',[_vm._v(" מחק ")])],1):_vm._e(),(msg.from != _vm.UID)?_c('v-list-item',{on:{"click":function($event){return _vm.UnReadMessage(msg)}}},[_c('v-list-item-title',[_vm._v(" חדש ")])],1):_vm._e()],1)],1)],1)],1)],1),(msg.from === _vm.UID)?_c('v-spacer'):_vm._e()],1)}),0):_vm._e()])],1),_c('v-row',{staticClass:"pt-3"},[_c('v-spacer'),_c('v-col',{attrs:{"cols":"12","sm":"9"}},[_c('v-app-bar',{attrs:{"color":"rgba(0,0,0,0)","flat":""}},[_c('v-text-field',{attrs:{"append-outer-icon":'mdi-send',"filled":"","clear-icon":"mdi-close-circle","clearable":"","label":"הודע חדשה","type":"text","disabled":_vm.disableToSend},on:{"click:append-outer":_vm.sendMessage,"click:clear":_vm.clearMessage,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.sendMessage.apply(null, arguments)}},model:{value:(_vm.message),callback:function ($$v) {_vm.message=$$v},expression:"message"}})],1)],1)],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }